
$color1: #333;
$container-size1: 700px;

$debug: false;

// mixin that show border if $debug is true
@mixin debug($color: red, $size: 1px) {
  @if $debug {
    border: $size solid $color;
  }
}

html {
  padding: 0;
  margin: 0;
  color: $color1;
}

a {
  color: $color1;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

main {
  padding: 0;
  margin: 0;
  font-family: 'Kumbh Sans', sans-serif;
  transition: all 0.5s;

  #headline {
    display: flex;
    justify-content: center;
    #logo-link {
      min-width: 200px;
      max-width: 20vw;
      display: block;
    }
  }

  #video-container{
    width: 100%;
    @include debug();
  }

  blockquote {
    font-style: italic;
  }

}

footer {
  .logo{
    width: 30px;
  }
}

@media screen and (min-width: 480px) {
  main {
    #video{
      iframe{
        height: 300px;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  main {
    #video{
      iframe{
        height: 400px;
      }
    }
  }
}


