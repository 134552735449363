html {
  color: #333;
  margin: 0;
  padding: 0;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

main {
  margin: 0;
  padding: 0;
  font-family: Kumbh Sans, sans-serif;
  transition: all .5s;
}

main #headline {
  justify-content: center;
  display: flex;
}

main #headline #logo-link {
  min-width: 200px;
  max-width: 20vw;
  display: block;
}

main #video-container {
  width: 100%;
}

main blockquote {
  font-style: italic;
}

footer .logo {
  width: 30px;
}

@media screen and (min-width: 480px) {
  main #video iframe {
    height: 300px;
  }
}

@media screen and (min-width: 576px) {
  main #video iframe {
    height: 400px;
  }
}

/*# sourceMappingURL=index.1d6bbd4a.css.map */
